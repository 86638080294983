import React from "react"
import { Link } from "gatsby"
import { Layout, SEO } from "../components"
const title = "404: Not found"

const NotFoundPage = () => (
  <Layout title={title}>
    <SEO title={title} />
    <section className="error">
      <div className="container">
        <div className="eight columns offset-by-one-and-one-half content-container">
          <h3>404: Page Not Found</h3>
          <h6>The requested page does not exist.</h6>
          <Link to="/">
            <button>Home</button>
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
